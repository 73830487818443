import moment from "moment";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const ReapitAppointment = ({ appointment }) => (
	<p>{moment(appointment.date).format("DD MMM hh:mm A")} | {appointment.address}</p>
);

const ReapitLeadInfo = ({ referralProp: { lead_info, referrer } }) => (
	<Col>
		<Row className="mb-2">
			<Col>
				<Form.Label className="mb-1"><b>Referred by:</b></Form.Label>
				<p>{referrer.name}</p>
			</Col>
			<Col>
				<Form.Label className="mb-1"><b>Selling Position:</b></Form.Label>
				<p>{lead_info.sellingPosition}</p>
			</Col>
		</Row>
		<Row className="mb-2">
			<Col>
				<Form.Label className="mb-1"><b>Notes:</b></Form.Label>
				<p>{lead_info.notes || "None"}</p>
			</Col>
		</Row>
		<Row>
			<Col>
				<Form.Label className="mb-1"><b>Appointments:</b></Form.Label>
				{lead_info.appointments?.map((e, i) => <ReapitAppointment key={`appt-${i}`} appointment={e} />)}
			</Col>
		</Row>
	</Col>
);

export default ReapitLeadInfo;