import React, { useState, useEffect, useCallback } from "react";
import { Spinner,  Button, Row, Card, Form, Container } from "react-bootstrap";
import { getAPI, postAPI } from "utils/requestAPI";
import DocumentTitle from "components/shared/documentTitle";
import CallCard from "./CallCard";
import CallDetails from "./CallDetails";

const GaxLeads = () =>
{
	const [isPmpCall, setIsPmpCall] = useState(false);
	const [startCall, setStartCall] = useState(false);
	const [noCalls, setNoCalls] = useState(false);
	const [loading, setLoading] = useState(false);
	const [gaxCall, setGaxCall] = useState(null);
	const [referralProp, setReferralProp] = useState(null);
	const [addressConfirmed, setAddressConfirmed] = useState(false);
	const [addresses, setAddresses] = useState([]);
	const [referrer, setReferrer] = useState(null);

	useEffect(() =>
	{
		if(startCall)
			fetchData();
	}, [startCall]);

	const updateReferralProp = (updateObj) =>
	{
		setReferralProp({...referralProp, ...updateObj});
	};

	const saveReferralProp = async(updateObj, confirm) =>
	{
		if(confirm)
			setAddressConfirmed(true);

		try
		{
			await postAPI(`/admin/referrals/${referralProp.id}/update`, {...updateObj});
		}
		catch (err)
		{
			alert("Error while updating Gax call details after end of call: " + err.message);
		}

		fetchData(true);
	};

	const fetchData = async(noload) =>
	{
		if(!noload)
			setLoading(true);

		const resp = await getAPI(`/admin/gaxCalls/next?rId=292369&exclude=${isPmpCall ? "0" : "1"}`, {skipValidate: true});

		if(resp.error && resp.type === "NO_CALLS_AVAILABLE")
			return setNoCalls(true);

		let { gaxCall } = resp;

		setGaxCall(gaxCall);
		setReferralProp(gaxCall.referralProp);
		setReferrer(gaxCall.referralProp.referrer);
		fetchAddress(gaxCall.referralProp.postcode);
		setLoading(false);
	};

	const fetchCallback = async() =>
	{
		try
		{
			const { scheduledCall } = await postAPI(`/admin/gaxCalls/${gaxCall.id}/outcome`, {
				outcome: "ANSWERED_CALL_BACK",
				whyNoVals: null,
				callAt: gaxCall.call_at
			});

			setGaxCall(scheduledCall);
		}
		catch (err)
		{
			alert("Error while creating callback: " + err.message);
		}
	};

	const fetchAddress = async(postcode) =>
	{
		try
		{
			const addresses = await getAPI(`/address/list?postcode=${postcode}`);

			setAddresses(addresses.result);
		}
		catch (e)
		{
			console.log("ERROR: ", e);
		}
	};

	if(!startCall)
	{
		return (
			<Container>
				<DocumentTitle title="GetAgent Exchange Call System" />
				<Card className="max-width-5">
					<Card.Body>
						<Row className="justify-content-center">
							<h2>Welcome to the GetAgent Exchange Call System</h2>
						</Row>
						<Row className="justify-content-center align-items-center mt-4">
							<Form.Label>Select call type:</Form.Label>
						</Row>
						<Row className="justify-content-center mb-4">
							<Form.Check
								inline
								type="radio"
								label="GetAgent Exchange"
								onChange={() => setIsPmpCall(false)}
								checked={isPmpCall === false}
							/>
							<Form.Check
								inline
								type="radio"
								label="Premarket"
								onChange={() => setIsPmpCall(true)}
								checked={isPmpCall}
								className="ml-2"
							/>
						</Row>
						<Row>
							<Button
								onClick={() => setStartCall(true)}
								className="ml-auto mr-auto"
								disabled={isPmpCall === null}
							>
								Get Next Call
							</Button>
						</Row>
					</Card.Body>
				</Card>
			</Container>
		);
	}

	if(noCalls)
		return <div className="text-center mt-5">There are no Gax calls to make!</div>;

	if(loading)
	{
		return (
			<div className="text-center mt-5">
				<Spinner size="lg" variant="primary" animation="border"/>
			</div>
		);
	}

	return (
		<>
			<div  style={{ maxWidth: 1800 }}>
				<div className="page-header mt-5">
					<h1> GAX Leads </h1>
				</div>
				{gaxCall ? (
					<div className="pb-4">
						<CallDetails
							gaxCall={gaxCall}
							fetchData={fetchData}
							fetchCallback={fetchCallback}
							referralProp={referralProp}
							referrer={referrer}
						/>
						<div style={{paddingTop: "8px"}}>
							<CallCard
								referralProp={referralProp}
								addresses={addresses}
								updateReferralProp={updateReferralProp}
								saveReferralProp={saveReferralProp}
								addressConfirmed={addressConfirmed}
								fetchAddress={fetchAddress}
							/>
						</div>
					</div>
				) : (
					<div>
						<h2>No calls!</h2>
						<p>There are no current calls for you to make.</p>
					</div>
				)}
			</div>
		</>
	);
};

export default GaxLeads;