import React, {useState, useContext, useEffect} from "react";
import { getAPI, postAPI } from "utils/requestAPI";
import Caller from "../call-form/caller";
import {Card, Row, Col, Form, Button } from "react-bootstrap";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import moment from "moment";
import NoteInput from "components/shared/noteInput";
import BdCrmPanelDateTime from "components/bd-crm/bdCrmPanelDateTime";
import ReapitLeadInfo from "./ReapitLeadInfo";
import LrgLeadInfo from "./LrgLeadInfo";

const CallDetails = ({referralProp = {}, gaxCall, fetchData, fetchCallback }) =>
{
	const [loading, setLoading] = useState(true);
	const [outcome, setOutcome] = useState(null);
	const [whyNoVals, setWhyNoVals] = useState(null);
	const [callBackTime, setCallBackTime] = useState(false);
	const [callStatus, setCallStatus] = useState();
	const [twilioToken, setTwilioToken] = useState(null);
	const [callerReady, setCallerReady] = useState(false);
	const [clientCallId, setClientCallId] = useState(null);
	const [startCall, setStartCall] = useState(false);
	const [isNoteFilled, setIsNoteFilled] = useState(false);

	const { GA_CONFIG } = useContext(GAConfigContext);

	useEffect(() =>
	{
		fetchTwilioToken();
	}, []);


	const fetchTwilioToken = async() =>
	{
		setLoading(true);
		try
		{
			const { twilioToken } = await getAPI("/admin/twilioToken.json");

			setTwilioToken(twilioToken);
		}
		catch (err)
		{
			alert(err || "An error occurred.");
		}

		setLoading(false);
	};

	const handleOnCallEnd = async() =>
	{
		setStartCall(false);
		setCallerReady(true);

		setCallStatus("ENDED");

		if(clientCallId)
		{
			try
			{
				await postAPI(`/admin/gaxCalls/${gaxCall.id}/done`, { clientCallId: clientCallId });
			}
			catch (err)
			{
				alert("Error while updating Gax call details after end of call: " + err.message);
			}
		}
	};

	const handleStartCall = () =>
	{
		if(referralProp.mobile)
		{
			setStartCall(true);
			setCallerReady(false);
			setCallStatus("IN_PROGRESS");
		}
		else
		{
			alert("No number to call...");
		}
	};

	const handleSaveGaxCall = async() =>
	{
		if(isNoteFilled)
		{
			alert("There is an unsaved note");
			return;
		}

		try
		{
			await postAPI(`/admin/gaxCalls/${gaxCall.id}/outcome`, {
				outcome: outcome,
				whyNoVals: whyNoVals,
				callAt: callBackTime
			});
		}
		catch (err)
		{
			alert("Error while updating outcome: " + err.message);
		}

		setCallStatus("SAVED");
	};

	const handleCallback = async() =>
	{
		if(isNoteFilled)
		{
			alert("There is an unsaved note");
			return;
		}

		await fetchCallback();

		setCallStatus();
		setOutcome(null);
		setWhyNoVals(null);
		setCallBackTime(null);
	};

	const handleNoteChange = (e) =>
	{
		setIsNoteFilled(!!e);
	};

	const handleNoteSubmit = () =>
	{
		setIsNoteFilled(false);
	};

	if(loading)
		return <div>Loading</div>;

	return (
		<Card>
			<Card.Body>
				<Row className="mb-2">
					<Col xs={12} md={6}>
						<Form.Label className="mb-1"><b>Call type:</b></Form.Label>
						<p>{gaxCall.call_type}</p>
						{callStatus !== "ENDED" && callStatus !== "SAVED" ? (
							<Row>
								<Col xs={12} md={6}>
									<Caller
										phoneNum={referralProp.mobile}
										callerReady={(ready) => setCallerReady(ready)}
										twilioToken={twilioToken}
										startCall={startCall}
										onCallEnd={handleOnCallEnd}
										setCallClientId={setClientCallId}
									/>
									{callerReady && (
										<Button onClick={handleStartCall}>Make the call</Button>
									)}
								</Col>
							</Row>
						) : null}
					</Col>
					<Col xs={12} md={6}>
						<Row>
							{referralProp.platform === "reapit" ? (
								<ReapitLeadInfo referralProp={referralProp} />
							) : (
								<LrgLeadInfo referralProp={referralProp} />
							)}
						</Row>
					</Col>
				</Row>
				<Row>
					<Col>
						{callStatus && (
							<div className="mr-4">
								<Form.Label>
										New Note:
								</Form.Label>
								<NoteInput
									referralProp={referralProp}
									gaxCall={gaxCall}
									allowReminder={false}
									fullWidth
									onNoteChange={handleNoteChange}
									onNoteSubmitted={handleNoteSubmit}
								/>
							</div>
						)}
					</Col>
					<Col className="ml-4">
						<Row>
							{callStatus === "ENDED" && (
								<Form.Group>
									<Form.Label>
										Call Outcome:
									</Form.Label>
									<Form.Control
										as="select"
										value={outcome ?? ""}
										onChange={(e) => setOutcome(e.target.value)}
									>
										<option value="" disabled hidden>Select outcome</option>
										{GA_CONFIG.gaxCalls.outcomes.map(o =>
											<option key={o} value={o}>{o}</option>
										)}
									</Form.Control>
									{outcome === "ANSWERED_NO_VALS" &&
									<>
										<Form.Label>
												Outcome Reason:
										</Form.Label>
										<Form.Control
											as="select"
											value={whyNoVals || GA_CONFIG.gaxCalls.reasons[0]}
											onChange={(e) => setWhyNoVals(e.target.value)}
										>
											<option value="-">Select Reason</option>
											{GA_CONFIG.gaxCalls.reasons.map(o =>
												<option key={o} value={o}>{o}</option>
											)}
										</Form.Control>
									</>
									}
									{outcome === "ANSWERED_CALL_BACK" &&
									<>
										<BdCrmPanelDateTime title="Call Back Time" onChange={(e) => setCallBackTime(e ? moment(e).format("YYYY-MM-DDTHH:mm") : null)} />
									</>
									}
									<Button onClick={handleSaveGaxCall} className="mt-4 mr-2" disabled={!outcome || (outcome === "ANSWERED_CALL_BACK" && !callBackTime)}>Save outcome</Button>
									<Button onClick={handleCallback} className="mt-4" variant="danger">Call back now</Button>
								</Form.Group>
							)}
							{callStatus === "SAVED" && (
								<Button onClick={() =>
								{
									setCallStatus(null);
									fetchData();
								}}>Next call!</Button>
							)}
						</Row>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

export default CallDetails;