import React from "react";
import { Row, Card, Col, Form, FormControl, InputGroup, Container } from "react-bootstrap";
import _ from "underscore";
import moment from "moment";
import { GAConfigContext } from "../../contexts/gaConfigContext";
import CohortChart from "./cohortChart";
import { getAPI } from "utils/requestAPI";

class CohortTrackerPage extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {
			cohortSourceFilter: props.filterBy ? props.filterBy : "ShowAll",
			view: props.view ? props.view : "months",
			date: props.date ? props.date : moment().format("YYYY-MM-DD"),
			acquisition: props.acquisition ? props.acquisition : "show-all",
			tableStatusOrder: [0, 550, 650, 750, 800, 850, 900, 1000, 1100, 1200]
		};

		this.onSourceFilterChange = this.onSourceFilterChange.bind(this);
		this.onAcquisitionFilterChange = this.onAcquisitionFilterChange.bind(this);
		this.onViewChange = this.onViewChange.bind(this);
		this.fetchCohortChartData = this.fetchCohortChartData.bind(this);
		this.processCohortStatusData = this.processCohortStatusData.bind(this);
	}

    static contextType = GAConfigContext

    fetchCohortChartData()
    {
    	this.setState({cohortData: null});

    	getAPI(`/admin/vendor/cohortData.json?filterBy=${this.state.cohortSourceFilter}&view=${this.state.view}&date=${this.state.date}&acquisition=${this.state.acquisition}`)
    		.then(res =>
    		{

    			this.processCohortStatusData(res.data);


    		})
    		.catch(err => console.error(err));
    }

    processCohortStatusData(cohortData)
    {

    	cohortData = _.mapObject(cohortData, (obj) =>
    	{
    		// sum up all the cohort values to get the total
    		obj.total = _.reduce(_.values(obj.cohort), (memo, num) => memo + num, 0);
    		obj.totalVals = 0;

    		const newCohort = {};
    		let numSoFar = obj.total;

    		_.each(this.state.tableStatusOrder, status =>
    		{
    			newCohort[status] = numSoFar;

    			if(obj.cohort[status])
    			{
    				numSoFar = numSoFar - obj.cohort[status];

    				if(status >= this.context.GA_CONFIG.vp.status.VALUATION_REQ)
    					obj.totalVals += obj.cohort[status];
    			}
    		});

    		obj.cohort = newCohort;
    		return obj;
    	});

    	this.setState({ cohortData });
    }

    componentDidMount()
    {
    	this.fetchCohortChartData();
    	window.history.pushState(null, null, `?filterBy=${this.state.cohortSourceFilter}&view=${this.state.view}&date=${this.props.date || moment().format("YYYY-MM-DD")}&acquisition=${this.state.acquisition}`);
    }

    onSourceFilterChange(e)
    {
    	this.setState({ cohortSourceFilter: e.target.value  }, () =>
    	{
    		window.history.pushState(null, null, `?filterBy=${this.state.cohortSourceFilter}&view=${this.state.view}&date=${this.state.date}&acquisition=${this.state.acquisition}`);
    		this.fetchCohortChartData();
    	});
    }

    onAcquisitionFilterChange(e)
    {
    	this.setState({ acquisition: e.target.value }, () =>
    	{
    		window.history.pushState(null, null, `?filterBy=${this.state.cohortSourceFilter}&view=${this.state.view}&date=${this.state.date}&acquisition=${this.state.acquisition}`);
    		this.fetchCohortChartData();
    	});
    }

    onViewChange(e)
    {
    	this.setState({ view: e.target.value  }, () =>
    	{
    		window.history.pushState(null, null, `?filterBy=${this.state.cohortSourceFilter}&view=${this.state.view}&date=${this.state.date}&acquisition=${this.state.acquisition}`);
    		this.fetchCohortChartData();
    	});
    }

    onDateChange(e)
    {

    	this.setState({ date: e.target.value }, () =>
    	{
    		window.history.pushState(null, null, `?filterBy=${this.state.cohortSourceFilter}&view=${this.state.view}&date=${this.state.date}`);

    		if(this.state.date)
    			this.fetchCohortChartData();
    	});
    }

    render()
    {
    	const header = () =>
    	{
    		return (
    		<>
    			<Row style={{ padding: "10px 0"}}>
    				<Col sm={2}><p>Cohort Analysis</p></Col>
    			</Row>
    			<Row>
    				<Col sm={3}>
    					<InputGroup className="date" style={{margin:"4px 4px 4px 0"}}>
    						<FormControl type="date" name="fromDate" onChange={this.onDateChange.bind(this)} value={this.state.date}/>
    						<InputGroup.Prepend>
    							<i className="glyphicon glyphicon-calendar" />
    						</InputGroup.Prepend>
    					</InputGroup>
    				</Col>
    				<Col sm={2}>
    					<Form inline>
    						<Form.Group style={{width:"100%"}}>
    							<Form.Text>View:</Form.Text>
    							<FormControl as="select" style={{margin:"4px", width:"50%"}} onChange={this.onViewChange} value={this.state.view}>
    								<option value="months">Months</option>
    								<option value="weeks">Weeks</option>
    								<option value="days">Days</option>
    							</FormControl>
    						</Form.Group>
    					</Form>
    				</Col>
    				<Col sm={4}>
    					<Form inline>
    						<Form.Group>
    							<Form.Text>Lead source:</Form.Text>
    							<FormControl as="select" style={{margin:"4px"}} onChange={this.onSourceFilterChange} value={this.state.cohortSourceFilter}>
    								<option value="show-all">Show All</option>
    								<option value="google-brand">Google Search Brand</option>
    								<option value="google-core">Google Search Core</option>
    								<option value="google-hw">Google Search HW</option>
    								<option value="google-pmax">Google Search Performance Max</option>
    								<option value="google-search">Google Search Other</option>
    								<option value="bing-cpc">Bing CPC</option>
    								<option value="organic-google">Organic Google</option>
    								<option value="organic-bing">Organic Bing</option>
    								<option value="organic-other">Organic Other</option>
    								<option value="paid-social">Paid Social</option>
    								<option value="exchange">GAX</option>
    								<option value="which">Which?</option>
    								<option value="partners">Partners</option>
    								<option value="other">Others</option>
    							</FormControl>
    						</Form.Group>
    					</Form>
    				</Col>
    				<Col sm={3}>
    					<Form inline>
    						<Form.Group>
    							<Form.Text>Acquisition:</Form.Text>
    							<FormControl as="select" style={{margin:"4px"}} onChange={this.onAcquisitionFilterChange} value={this.state.acquisition}>
    								<option value="show-all">Show All</option>
    								<option value="vendor-first">Vendor First</option>
    								<option value="prospect-first">Prospect First</option>
    							</FormControl>
    						</Form.Group>
    					</Form>
    				</Col>
    			</Row>
    		</>
    		);
    	};


    	return (
    		<div className="mx-2">

    			<Card>
    				<Card.Header style={{height:"auto", paddingLeft: "0", paddingRight: "0"}}><Container><Card.Title>{header()}</Card.Title></Container></Card.Header>

    				<CohortChart
    					filterBy={this.state.cohortSourceFilter}
    					view={this.state.view}
    					date={this.state.date}
    					cohortData={this.state.cohortData}
    				/>
    			</Card>
    		</div>
    	);
    }

}

export default CohortTrackerPage;