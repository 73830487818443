import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Route } from "react-router-dom";
import LeadTrackerPage from "./leadTrackerPage";
import CohortTrackerPage from "./cohortTrackerPage";
import DocumentTitle from "../shared/documentTitle";

class AnalyticsDashboard extends React.Component
{
	constructor(props)
	{
		super(props);
		this.state = {active: ""};

		this.queryParams = new URLSearchParams(window.location.search);
	}

	render()
	{

		return (
			<>
				<DocumentTitle title="Analytics dashboard" />

				<div className={window.location.pathname == "/vendor/analyticsDashboard/cohort" ? "container" : null}>
					<Navbar bg="light" className="mb-3" expand="xl">
						<Navbar.Brand className="ml-4">
						📈 Analytics Dashboard
						</Navbar.Brand>
						<Navbar.Toggle aria-controls="analytics-navbar" />
						<Navbar.Collapse id="analytics-navbar">
							<Nav navbar className="ml-4 mr-auto">
								<LinkContainer to="/vendor/analyticsDashboard/leads">
									<Nav.Link className="mr-2">
									Lead Analytics
									</Nav.Link>
								</LinkContainer>
								<LinkContainer to="/vendor/analyticsDashboard/cohort">
									<Nav.Link >
									Cohort Analytics
									</Nav.Link>
								</LinkContainer>
							</Nav>
						</Navbar.Collapse>
					</Navbar>
				</div>
				<Route path="/vendor/analyticsDashboard/leads">
					<LeadTrackerPage
						fromDate={this.queryParams.get("fromDate")}
						toDate={this.queryParams.get("toDate")}
					/>
				</Route>
				<Route path="/vendor/analyticsDashboard/cohort">
					<CohortTrackerPage
						filterBy={this.queryParams.get("filterBy")}
						view={this.queryParams.get("view")}
						date={this.queryParams.get("date")}
						acquisition={this.queryParams.get("acquisition")}
					/>
				</Route>
			</>
		);
	}
}

export default AnalyticsDashboard;