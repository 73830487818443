import React from "react";
import { Col, Form, Row } from "react-bootstrap";

const LrgLeadInfo = ({ referralProp: { lead_info, referrer } }) => (
	<Col>
		<Row className="mb-2">
			<Col>
				<Form.Label className="mb-1"><b>Referred by:</b></Form.Label>
				<p>{referrer.name}</p>
			</Col>
			<Col>
				<Form.Label className="mb-1"><b>Branch:</b></Form.Label>
				<p>{lead_info.branch}</p>
			</Col>
		</Row>
		<Row className="mb-2">
			<Col>
				<Form.Label className="mb-1"><b>Notes:</b></Form.Label>
				<p>{lead_info.notes || "None"}</p>
			</Col>
		</Row>
	</Col>
);

export default LrgLeadInfo;